import React, {Component} from 'react';
import {XYPlot,XAxis,YAxis,HorizontalGridLines,VerticalGridLines,LineMarkSeries,Hint} from 'react-vis';
import 'react-vis/dist/style.css';





class Chart extends Component {
  
    constructor(props) {
      super(props);
      this.state = {
        value: null
      };

      this._rememberValue = this._rememberValue.bind(this);
      this._forgetValue = this._forgetValue.bind(this);
      
    }
  
    
    _forgetValue = () => {
      this.setState({
        value: null
      });
    };
  
    _rememberValue = value => {
      this.setState({value});
    };
  
    
    render() {
      
      var data_array = [];
      for(let i = 0; i < this.props.temperature.length; i++) {
        var data = [];  
        for(let j = 1; j < this.props.temperature[0].length; j++){
            
            data.push({x: j, y: this.props.temperature[i][j]});
             
        }
        data_array.push(data);      
      } 
     
      
      for (let i = 0; i < this.props.temperature[0].length; i++) {
              var lines = [];
              for(let j=0 ; j < data_array.length;j++){
                lines.push(<LineMarkSeries
                  onValueMouseOver={this._rememberValue}
                  onValueMouseOut={this._forgetValue} 
                  className="linemark-series-example-2"
                  curve={'curveMonotoneX'} 
                  key = {j}
                  data = {data_array[j]}
                  />);
              }
  
      }
        
      return (
        <div>
          <h4>График нагрева заготовки</h4>
          <XYPlot width={1000} height={500}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis title={'Длина печи'}/>
            <YAxis title={'Температура'}/>
            {lines}
            {this.state.value ? <Hint value={this.state.value} /> : null}
            </XYPlot>
        </div>
      );
    }
  }
  
  export default Chart;