export default [
    {
        "material": "Сталь",
        "teploprovodnost": "47",
        "temperaturaprovodnost": "0.00001172",
        "udelobteploemkost": "3713000",
    },
    {
        "material": "Алюминий",
        "teploprovodnost": "236",
        "temperaturaprovodnost": "0.00008418",
        "udelobteploemkost": "3713000",
    },
    {
        "material": "Медь",
        "teploprovodnost": "390",
        "temperaturaprovodnost": "0.000111",
        "udelobteploemkost": "85.853",
    },
    {
        "material": "Железо",
        "teploprovodnost": "92",
        "temperaturaprovodnost": "0.000023",
        "udelobteploemkost": "659900",
    },
]