import React, {Component} from 'react';

class Material extends Component{
    
    

    render()
        {
            return(
                <div>
                    Материалы
                </div>   
           )
        }
}

export default Material;