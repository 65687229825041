import React, {Component} from 'react';
import '../styles/Identification.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class Identification extends Component{
    
    constructor() {
        super();
        
        this.state = {
          mode: 'Конвекция',
          isCulc: false
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      
      handleChange(event) {
        this.setState({
          mode: event.target.value,
          isCulc:false
        });
      }
      
      handleSubmit(event) {
        event.preventDefault();
        
        if(this.state.mode === "Конвекция"){
            console.log("Считаю конвекцию");
            const data = this.props.model;
            let Length_pech = 0;
            for (let i = 0; i <= data.Length.length - 1; i++)
            {
                Length_pech = Length_pech + data.Length[i];
            }
            
            let StepCulc = data.StepLength / data.Speed;
            let StepCount = Math.round((data.Thickness / data.StepThickness)*1/1);
            let tme = Length_pech / data.Speed;
            let kmax = Math.round((tme / StepCulc)*1/1);
            let ss = 0;
            let ba = new Array(100);
            let k11 = [];
            for (let i = 0; i <= data.Count - 1; i++)
            {
                ba[i] = data.AlfaReal[i] * data.StepThickness / data.Lambda;
                ss = ss + data.Length[i];
                k11[i] = ss / data.StepLength;
            }
            let f = (data.Tempprov * StepCulc) / (data.StepThickness * data.StepThickness);

            let t1 = [];
            for (let i = 0; i <= StepCount; i++)
            {
                t1[i] = data.Temp_material;
            }

            let alfa = [];

            alfa[0] = (2 * f) / (1 + 2 * f);
            for (var i = 1; i <= StepCount - 1; i++)
            {
                alfa[i] = f / (1 + f * (2 - alfa[i - 1]));
            }
            
            let b = 0;
            let t2 = 0; 
            let beta = []; 
            let t = []; 
                     
            for (let k = 1; k <= kmax; k++)
            {
                if (k <= k11[0])
                {
                    b = ba[0];
                    t2 = data.TempGaz[0];
                }
                else if (k <= k11[1])
                {
                    b = ba[1];
                    t2 = data.TempGaz[1];
                }
                else if (k <= k11[2])
                {
                    b = ba[2];
                    t2 = data.TempGaz[2];
                }
                else if (k <= k11[3])
                {
                    b = ba[3];
                    t2 = data.TempGaz[3];
                }
                else if (k <= k11[4])
                {
                    b = ba[4];
                    t2 = data.TempGaz[4];
                }


                beta[0] = t1[0] / (1 + 2 * f);
                for (let i = 1; i <= StepCount - 1; i++)
                {
                    beta[i] = (t1[i] + f * beta[i - 1]) / (1 + f * (2 - alfa[i - 1]));
                }

                t1[StepCount] = (t1[StepCount] + 2 * f * beta[StepCount - 1] + b * t2) / (1 + 2 * f * (1 + b - alfa[StepCount - 1]));

                //Обратная перегонка
                for (let i = StepCount - 1; i >= 0; i--)
                {
                    t1[i] = alfa[i] * t1[i + 1] + beta[i];
                }

                t[k]=[];
                for (let i = StepCount; i >= 0; i--)
                {

                    t[k][i] = Math.round(t1[i]*(1/Number(this.refs.Tochnost.value)))/(1/Number(this.refs.Tochnost.value));
                }

            }
            if(this.refs.Rac.value>Length_pech){
                alert('Расстояние до точки измерения больше длины печи!');
                this.refs.Rac.value = Length_pech;
            }
            let k1 = Math.round(this.refs.Rac.value / data.StepLength);
            
            let p = Math.round(Math.abs(Number(this.refs.Temp_izmer.value) - t[k1][StepCount])*1000)/1000;

            document.getElementById('zone').innerText =  Number(this.refs.Zone_number.value);
            document.getElementById('eps').innerText = data.EPS[Number(this.refs.Zone_number.value)-1];
            document.getElementById('top_temperature').innerText = t[k1][StepCount];
            document.getElementById('temperature_izmer').innerText = Number(this.refs.Temp_izmer.value);
            document.getElementById('optimal').innerText = p;
            this.setState({isCulc:true});
            
            
            

        }
        if(this.state.mode === "Излучение"){
            
            console.log("Считаю излучение");

            const data = this.props.model;
            let Length_pech = 0;
            for (let i = 0; i <= data.Length.length - 1; i++)
            {
                Length_pech = Length_pech + data.Length[i];
            }

            let StepCulc = data.StepLength / data.Speed;
            let StepCount = Math.round((data.Thickness / data.StepThickness)*1/1);
            let tme = Length_pech / data.Speed;
            let kmax = Math.round((tme / StepCulc)*1/1);
            
            let m = 10; //Число итераций 

            const sigma = 0.0000000567;

            let ss = 0;
            let k11 = [];
            for (let i = 0; i <= data.Count - 1; i++)
            {
                ss = ss + data.Length[i];
                k11[i] = ss / data.StepLength;
            }

            let f = (data.Lambda * StepCulc) / (data.Cm * data.StepThickness * data.StepThickness);

            let t1 = [];

            for (let i = 0; i <= StepCount; i++)
            {
                t1[i] = data.Temp_material;
            }

            let alfa = [];
            let t2 = 0; 
            let beta = []; 
            let t = [];

            alfa[0] = (2 * f) / (1 + 2 * f);
            for (let i = 1; i <= StepCount - 1; i++)
            {
                alfa[i] = f / (1 + f * (2 - alfa[i - 1]));
            }

            let PrimeroneZnach = 0;
            for (let k = 1; k <= kmax; k++)
            {
                if (k <= k11[0])
                {
                    t2 = data.TempGaz[0];
                    PrimeroneZnach = data.EPS[0];
                }
                else if (k <= k11[1])
                {
                    t2 = data.TempGaz[1];
                    PrimeroneZnach = data.EPS[1];
                }
                else if (k <= k11[2])
                {
                    t2 = data.TempGaz[2];
                    PrimeroneZnach = data.EPS[2];
                }
                else if (k <= k11[3])
                {
                    t2 = data.TempGaz[3];
                    PrimeroneZnach = data.EPS[3];
                }
                else if (k <= k11[4])
                {
                    t2 = data.TempGaz[4];
                    PrimeroneZnach = data.EPS[4];
                }



                beta[0] = t1[0] / (1 + 2 * f);
                for (let i = 1; i <= StepCount - 1; i++)
                {
                    beta[i] = (t1[i] + f * beta[i - 1]) / (1 + f * (2 - alfa[i - 1]));
                }

                let tt = t1[StepCount];
                let q0 = 0;

                let delt = 0;
                for (let i = 1; i <= m + 1; i++)
                {
                    q0 = PrimeroneZnach * sigma * (t2 * t2 * t2 * t2 - tt * tt * tt * tt);
                    delt = q0 * data.StepThickness / data.Lambda;
                    tt = (t1[StepCount - 1] + 2 * f * (delt + beta[StepCount - 1])) / (1 + 2 * f * (1 - alfa[StepCount - 1]));
                }
                t1[StepCount] = tt;

                for (let i = StepCount - 1; i >= 0; i--)
                {
                    t1[i] = alfa[i] * t1[i + 1] + beta[i];
                }

                t[k]=[];
                for (let i = StepCount; i >= 0; i--)
                {
                    t[k][i] = Math.round(t1[i]*(1/Number(this.refs.Tochnost.value)))/(1/Number(this.refs.Tochnost.value));
                }
                
            }
            if(this.refs.Rac.value>Length_pech){
                alert('Расстояние до точки измерения больше длины печи!');
                this.refs.Rac.value = Length_pech;
            }
            let k1 = Math.round(this.refs.Rac.value / data.StepLength);
            
            let p = Math.round(Math.abs(Number(this.refs.Temp_izmer.value) - t[k1][StepCount])*1000)/1000;

            document.getElementById('zone').innerText =  Number(this.refs.Zone_number.value);
            document.getElementById('eps').innerText = data.EPS[Number(this.refs.Zone_number.value)-1];
            document.getElementById('top_temperature').innerText = t[k1][StepCount];
            document.getElementById('temperature_izmer').innerText = Number(this.refs.Temp_izmer.value);
            document.getElementById('optimal').innerText = p;
            this.setState({isCulc:true});
            


        }

        
    }
    
      render() {
          
        return (
        <div className="modeling">
            <fieldset>
                <legend>Режим нагрева</legend>
                <form 
                    className="selectMode"
                    onSubmit={this.handleSubmit}>
                    <div>
                    <div>
                    <label>
                        <input
                            className="radioButton"
                            type="radio"
                            value="Конвекция"
                            checked={this.state.mode === "Конвекция"}
                            onChange={this.handleChange}
                        />
                        Конвекция
                        </label>
                        </div>
                    <div>
                    
                        <label>
                        <input 
                            className="radioButton"
                            type="radio"
                            value="Излучение"
                            checked={this.state.mode === "Излучение"}
                            onChange={this.handleChange}
                        />
                        Излучение
                        </label>
                    </div>
                    </div>
                    <button type="submit" className="btn btn-success">Расчет</button>
                    {
                        this.state.isCulc ?
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button btn btn-success"
                            table="table-to-xls"
                            filename="Результаты идентификации"
                            sheet={"Нагрев: " + this.state.mode}
                            buttonText="Сохранить"/>
                            : null
                    }
                </form>
            </fieldset>
            <div className="row additional_parameters">     
                <div className="col-md-6">
                    <h4>Дополнительные исходные данные</h4>
                    <div className="text form-group">
                            <label className="col-md-9">Расстояние до точки измерения температуры, м</label>
                            <input className="form-control col-md-3"
                                   ref="Rac"
                                   defaultValue="12"
                                   placeholder="12"></input>
                    </div>
                    <div className="text form-group">
                            <label className="col-md-9">Измеренное значение температуры, С</label>
                            <input className="form-control col-md-3"
                                   ref="Temp_izmer"
                                   defaultValue="749"
                                   placeholder="749"></input>
                    </div>
                    <div className="text form-group">
                            <label className="col-md-9">Точность измерения температуры</label>
                            <input className="form-control col-md-3"
                                   ref="Tochnost"
                                   defaultValue="0.01"
                                   placeholder="0.01"></input>
                    </div>
                    <div className="text form-group">
                            <label className="col-md-9">Номер зоны где определяется</label>
                            <input className="form-control col-md-3"
                                   ref="Zone_number"
                                   defaultValue="4"
                                   placeholder="4"></input>
                    </div>
                    <div className="text form-group">
                            <label className="col-md-9">Примерное значение параметра в расчетной зоне</label>
                            <input className="form-control col-md-3"
                                   ref="Primer_znach"
                                   defaultValue="0.6"
                                   placeholder="0.6"></input>
                    </div>
                </div>
                <div className="col-md-6">
                    <h4>Результат идентификации</h4>
                    <table id="table-to-xls" className="table table-striped table-bordered">
                    <caption>Таблица результатов идентификации</caption>
                        <thead>
                            <tr>
                                <th>Параметр</th>
                                <th>Значение</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th>Теплотехническая зона</th>
                                <td id="zone"></td>
                            </tr>
                            <tr>
                            <th>Значение расчетное</th>
                                <td id="eps"></td>
                            </tr>
                            <tr>
                            <th>Температура поверхности в расчетной зоне</th>
                                <td id="top_temperature"></td>
                            </tr>
                            <tr>
                            <th>Измеренное значение температуры в зоне</th>
                                <td id="temperature_izmer"></td>
                            </tr>
                            <tr>
                            <th>Значение критерия оптимальности</th>
                                <td id="optimal"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>

        );
      }
}

export default Identification;