import React, { Component } from 'react';


class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    };
    
    render() {
        return (
            <table id="table-to-xls" className="table table-striped table-bordered">
                <caption>Таблица результатов моделирования нагрева</caption>
                <thead>
                <tr>
                    {this.props.wid.map((element, index) =>
                    <th key={index}>{element}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                    {this.props.temperature.map((element, index) =>
                        <tr key={index}>
                        {element.map((item, i) =>
                            <td key={i}>{item}</td>
                        )}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }
}

export default Table;