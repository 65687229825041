import React, { Component } from 'react';
import Menu from './Menu';
import Main from './Main';
import About from './About';
import Material from './Material';
import Identification from './Identification';
import Modeling from './Modeling';
import Material_property from '../Material_property.js';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            whichComponentToShow: 'Main',
            model:{}
        };
    };

    updateData = (value) => {
        this.setState({ model: value })
    }
    //функция изменения главного экрана
    ComponentToShow = () => {
        return {
            'Main': <Main Material_property={Material_property} data={this.state.model} updateData={this.updateData} ShowComponent={(value) => { 
                this.setState({ whichComponentToShow: value })
            }} />,
            'About': <About />,
            'Material':<Material/>,
            'Identification': <Identification model={this.state.model}/>,
            'Modeling': <Modeling model={this.state.model}/>
        }[this.state.whichComponentToShow]
    }

    render() {
        console.log(this.state);
        return (
            <div>
                <Menu ShowComponent={(value) => { 
                    this.setState({ whichComponentToShow: value })
                }} />
                {this.ComponentToShow()}
            </div>
        )
    }
}

export default App;
