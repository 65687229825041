import React, {Component} from 'react';
import '../styles/Main.css';


class Main extends Component{
    constructor(props) {
        super(props);
        this.state = {
            model: {
                Count: 5,
                Thickness: 0.08,
                StepThickness: 0.016,
                StepLength: 1,
                Speed: 0.1,
                Temp_material: 427,
                Lambda: 28,
                Tempprov: 0.0000064,
                Cm: 555203.1,
                Length: [],
                TempGaz: [],
                AlfaReal: [],
                EPS: [],
                Material: 'Сталь'
            },
        };
    };
    
    setIdent = this.setIdent.bind(this);
    setModel = this.setModel.bind(this);
    getDataFromTable = this.getDataFromTable.bind(this);
    zoneCount = this.zoneCount.bind(this);
    material_Change = this.material_Change.bind(this);

    setIdent(){
        
        // eslint-disable-next-line
        this.state.model.Count = Number(this.refs.Count.value);
        // eslint-disable-next-line
        this.state.model.Thickness = Number(this.refs.Thickness.value);
        // eslint-disable-next-line
        this.state.model.StepThickness = Number(this.refs.StepThickness.value);
        // eslint-disable-next-line
        this.state.model.StepLength = Number(this.refs.StepLength.value);
        // eslint-disable-next-line
        this.state.model.Speed = Number(this.refs.Speed.value);
        // eslint-disable-next-line
        this.state.model.Temp_material = Number(this.refs.Temp_material.value);
        // eslint-disable-next-line
        this.state.model.Lambda = Number(this.refs.Lambda.value);
        // eslint-disable-next-line
        this.state.model.Tempprov = Number(this.refs.Tempprov.value);
        // eslint-disable-next-line
        this.state.model.Cm = Number(this.refs.Cm.value);
        // eslint-disable-next-line
        this.state.model.Material = document.getElementById("Material").value;
        this.getDataFromTable();
        
        /* this.setState({model: {...this.state.model, Count: Number(this.refs.Count.value)}});
        this.setState({model: {...this.state.model, Thickness: Number(this.refs.Thickness.value)}});
        this.setState({model: {...this.state.model, StepThickness: Number(this.refs.StepThickness.value)}});
        this.setState({model: {...this.state.model, StepLength: Number(this.refs.StepLength.value)}});
        this.setState({model: {...this.state.model, Speed: Number(this.refs.Speed.value)}});
        this.setState({model: {...this.state.model, Temp_material: Number(this.refs.Temp_material.value)}});
        this.setState({model: {...this.state.model, Lambda: Number(this.refs.Lambda.value)}});
        this.setState({model: {...this.state.model, Tempprov: Number(this.refs.Tempprov.value)}});
        this.setState({model: {...this.state.model, Cm: Number(this.refs.Cm.value)}}); */ 
        this.props.updateData(this.state.model);
        this.props.ShowComponent('Identification');
    }
    getDataFromTable(){
        if(this.state.model.Count === 1){
            // eslint-disable-next-line
            this.state.model.Length[0] = Number(this.refs.LengthOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[0] = Number(this.refs.TempGazOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[0] = Number(this.refs.AlfaRealOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[0] = Number(this.refs.EPSOfFirstZone.value);
        }
        else if(this.state.model.Count === 2){
            // eslint-disable-next-line
            this.state.model.Length[0] = Number(this.refs.LengthOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.Length[1] = Number(this.refs.LengthOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[0] = Number(this.refs.TempGazOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[1] = Number(this.refs.TempGazOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[0] = Number(this.refs.AlfaRealOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[1] = Number(this.refs.AlfaRealOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[0] = Number(this.refs.EPSOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[1] = Number(this.refs.EPSOfSecondZone.value);
        }
        else if(this.state.model.Count === 3){
            // eslint-disable-next-line
            this.state.model.Length[0] = Number(this.refs.LengthOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.Length[1] = Number(this.refs.LengthOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.Length[2] = Number(this.refs.LengthOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[0] = Number(this.refs.TempGazOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[1] = Number(this.refs.TempGazOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[2] = Number(this.refs.TempGazOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[0] = Number(this.refs.AlfaRealOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[1] = Number(this.refs.AlfaRealOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[2] = Number(this.refs.AlfaRealOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[0] = Number(this.refs.EPSOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[1] = Number(this.refs.EPSOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[2] = Number(this.refs.EPSOfThirdZone.value);
        }
        else if(this.state.model.Count === 4){
            // eslint-disable-next-line
            this.state.model.Length[0] = Number(this.refs.LengthOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.Length[1] = Number(this.refs.LengthOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.Length[2] = Number(this.refs.LengthOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.Length[3] = Number(this.refs.LengthOfFourthZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[0] = Number(this.refs.TempGazOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[1] = Number(this.refs.TempGazOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[2] = Number(this.refs.TempGazOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[3] = Number(this.refs.TempGazOfFourthZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[0] = Number(this.refs.AlfaRealOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[1] = Number(this.refs.AlfaRealOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[2] = Number(this.refs.AlfaRealOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[3] = Number(this.refs.AlfaRealOfFourthZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[0] = Number(this.refs.EPSOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[1] = Number(this.refs.EPSOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[2] = Number(this.refs.EPSOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[3] = Number(this.refs.EPSOfFourthZone.value);
        }
        else if(this.state.model.Count === 5){
            // eslint-disable-next-line
            this.state.model.Length[0] = Number(this.refs.LengthOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.Length[1] = Number(this.refs.LengthOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.Length[2] = Number(this.refs.LengthOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.Length[3] = Number(this.refs.LengthOfFourthZone.value);
            // eslint-disable-next-line
            this.state.model.Length[4] = Number(this.refs.LengthOfFifthZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[0] = Number(this.refs.TempGazOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[1] = Number(this.refs.TempGazOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[2] = Number(this.refs.TempGazOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[3] = Number(this.refs.TempGazOfFourthZone.value);
            // eslint-disable-next-line
            this.state.model.TempGaz[4] = Number(this.refs.TempGazOfFifthZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[0] = Number(this.refs.AlfaRealOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[1] = Number(this.refs.AlfaRealOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[2] = Number(this.refs.AlfaRealOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[3] = Number(this.refs.AlfaRealOfFourthZone.value);
            // eslint-disable-next-line
            this.state.model.AlfaReal[4] = Number(this.refs.AlfaRealOfFifthZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[0] = Number(this.refs.EPSOfFirstZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[1] = Number(this.refs.EPSOfSecondZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[2] = Number(this.refs.EPSOfThirdZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[3] = Number(this.refs.EPSOfFourthZone.value);
            // eslint-disable-next-line
            this.state.model.EPS[4] = Number(this.refs.EPSOfFifthZone.value);
        } 
    }
    setModel(){
        if(this.refs.LengthOfFifthZone.value === "" && this.refs.Count.value === "5"||
        this.refs.TempGazOfFifthZone.value === "" && this.refs.Count.value === "5"||
        this.refs.AlfaRealOfFifthZone.value === "" && this.refs.Count.value === "5"||
        this.refs.EPSOfFifthZone.value === "" && this.refs.Count.value === "5"
        ){
            alert("Заполните данные по пятой зоне");    
        }
        else if(this.refs.LengthOfFourthZone.value === "" && this.refs.Count.value === "4"||
        this.refs.TempGazOfFourthZone.value === "" && this.refs.Count.value === "4"||
        this.refs.AlfaRealOfFourthZone.value === "" && this.refs.Count.value === "4"||
        this.refs.EPSOfFourthZone.value === "" && this.refs.Count.value === "4"
        ){
            alert("Заполните данные по четвертой зоне");    
        }
        else if(this.refs.LengthOfThirdZone.value === "" && this.refs.Count.value === "3"||
        this.refs.TempGazOfThirdZone.value === "" && this.refs.Count.value === "3"||
        this.refs.AlfaRealOfThirdZone.value === "" && this.refs.Count.value === "3"||
        this.refs.EPSOfThirdZone.value === "" && this.refs.Count.value === "3"
        ){
            alert("Заполните данные по третьей зоне");    
        }
        else if(this.refs.LengthOfSecondZone.value === "" && this.refs.Count.value === "2"||
        this.refs.TempGazOfSecondZone.value === "" && this.refs.Count.value === "2"||
        this.refs.AlfaRealOfSecondZone.value === "" && this.refs.Count.value === "2"||
        this.refs.EPSOfSecondZone.value === "" && this.refs.Count.value === "2"
        ){
            alert("Заполните данные по второй зоне");    
        }
        else{
        // eslint-disable-next-line     
        this.state.model.Count = Number(this.refs.Count.value);
        // eslint-disable-next-line
        this.state.model.Thickness = Number(this.refs.Thickness.value);
        // eslint-disable-next-line
        this.state.model.StepThickness = Number(this.refs.StepThickness.value);
        // eslint-disable-next-line
        this.state.model.StepLength = Number(this.refs.StepLength.value);
        // eslint-disable-next-line
        this.state.model.Speed = Number(this.refs.Speed.value);
        // eslint-disable-next-line
        this.state.model.Temp_material = Number(this.refs.Temp_material.value);
        // eslint-disable-next-line
        this.state.model.Lambda = Number(this.refs.Lambda.value);
        // eslint-disable-next-line
        this.state.model.Tempprov = Number(this.refs.Tempprov.value);
        // eslint-disable-next-line
        this.state.model.Cm = Number(this.refs.Cm.value);
        // eslint-disable-next-line
        this.state.model.Material = document.getElementById("Material").value;
        
        this.getDataFromTable();
       
        this.props.updateData(this.state.model);
        this.props.ShowComponent('Modeling'); 
        } 
    }
    
    zoneCount(e){
        console.log(e.target.value);  
                
        }
    
    material_Change(e){
        // eslint-disable-next-line
        this.state.model.Material = e.target.value;
        var Lambda = document.getElementById("Lambda");
        var Tempprov = document.getElementById("Tempprov");
        var Cm = document.getElementById("Cm");
        // eslint-disable-next-line
        this.props.Material_property.map(function (el, index) {
            if(el.material === e.target.value) {
                Lambda.value = el.teploprovodnost;
                Tempprov.value = el.temperaturaprovodnost;
                Cm.value = el.udelobteploemkost;
            }
        });
    }
    
    handleKeypress (e) {
        const characterCode = e.key
        if (characterCode === 'Backspace') return
        if (characterCode === 'Tab') return
        console.log(characterCode);
        if (characterCode === '.') return
        const characterNumber = Number(characterCode);
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
            return
          } else if (characterNumber === 0) {
            e.preventDefault()
          } 
        } else {
          e.preventDefault()
        }
      }
     
    render()
        {
            console.log('Main component render', this);
            return(
                <div className="row">
                        <div className="col-md-6">
                        <h4>Исходные данные</h4>
                        <div className="text form-group">
                            <label className="col-md-9">Число теплофизических зон</label>
                            <input
                            className="form-control col-md-3"
                            ref = "Count"
                            type="number"
                            min = "1"
                            max = "5"
                            id="pyat"
                            onKeyDown={this.handleKeypress.bind(this)}
                            onChange={this.zoneCount}
                            defaultValue={this.props.data.Count === undefined ? "5" : this.props.data.Count}
                            placeholder="5"
                            ></input>
                        </div>
                        <div className="text form-group">
                            <label className="col-md-9">Толщина нагреваемого металла, м</label>
                            <input className="form-control col-md-3"
                                    onKeyDown={this.handleKeypress.bind(this)}
                                    ref="Thickness" 
                                    defaultValue={this.props.data.Thickness === undefined ? "0.08" : this.props.data.Thickness}
                                    placeholder="0.08"></input>
                        </div>
                        <div className="text form-group">
                            <label className="col-md-9">Шаг расчета по толщине металла, м</label>
                            <input className="form-control col-md-3" 
                                    onKeyDown={this.handleKeypress.bind(this)}
                                    ref="StepThickness"
                                    defaultValue={this.props.data.StepThickness === undefined ? "0.016" : this.props.data.StepThickness}
                                    placeholder="0.016"></input>
                        </div>
                        <div className="text form-group">
                            <label className="col-md-9">Шаг расчета по длине, м</label>
                            <input className="form-control col-md-3"
                                    onKeyDown={this.handleKeypress.bind(this)}
                                    ref="StepLength"
                                    defaultValue={this.props.data.StepLength === undefined ? "1" : this.props.data.StepLength}
                                    placeholder="1"></input>
                        </div>
                        <div className="text form-group">
                            <label className="col-md-9">Скорость перемещения в печи, м/с</label>
                            <input className="form-control col-md-3"
                                    onKeyDown={this.handleKeypress.bind(this)}
                                    ref="Speed"
                                    defaultValue={this.props.data.Speed === undefined ? "0.1" : this.props.data.Speed}
                                    placeholder="0.1"></input>
                        </div>
                        <div className="text form-group">
                            <label className="col-md-9">Начальная температура металла, °С</label>
                            <input className="form-control col-md-3" 
                                   onKeyDown={this.handleKeypress.bind(this)}
                                   ref="Temp_material"
                                   defaultValue={this.props.data.Temp_material === undefined ? "427" : this.props.data.Temp_material}
                                   placeholder="427"></input>
                        </div>
                    </div>    
                    <div className="material_property col-md-6">
                            <h4>Свойства материала</h4>
                            <div className="text form-group">
                                <label className="col-md-8">Материал</label>
                                <select className="form-control" id="Material"
                                onChange={this.material_Change}
                                defaultValue={this.props.data.Material === undefined ? "Сталь" : this.props.data.Material}>
                                    {this.props.Material_property.map((obj) => 
                                        <option key={obj.material}>{obj.material}</option>
                                        )}
                                </select>
                            </div>
                            <div className="text form-group">
                                <label className="col-md-8">Теплопроводность, Вт/(м*К)</label>
                                <input type="text" className="form-control" ref="Lambda" id="Lambda" readOnly defaultValue={this.props.data.Lambda === undefined ? "28" : this.props.data.Lambda}></input>
                            </div>
                            <div className="text form-group">
                                <label className="col-md-8">Температурапроводность, м<sup>2</sup>/с</label>
                                <input type="text" className="form-control" ref="Tempprov" id="Tempprov" readOnly defaultValue={this.props.data.Tempprov === undefined ? "0.0000064" : this.props.data.Tempprov}></input>
                            </div>
                            <div className="text form-group">
                                <label className="col-md-8">Удельная объемная теплоемкость, Дж/(м<sup>3</sup>&#215;К)</label>
                                <input type="text" className="form-control" ref="Cm" id="Cm" readOnly defaultValue={this.props.data.Cm === undefined ? "555203.1" : this.props.data.Cm}></input>
                            </div>
                            </div>
                            <div className="furnace col-md-12">
                <h4>Параметры зон в печи</h4>
                    <div className="zones header">
                        <label className="name_param">Параметр</label>
                        <label className="name_zones">Зона 1</label>
                        <label className="name_zones">Зона 2</label>
                        <label className="name_zones">Зона 3</label>
                        <label className="name_zones">Зона 4</label>
                        <label className="name_zones">Зона 5</label>
                        
                    </div>
                    <div className="zones">
                        <label className="name_param">Длина теплотехнической зоны, м</label>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "LengthOfFirstZone" defaultValue={this.props.data.Length === undefined ? "3" : this.props.data.Length[0]} placeholder="3"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "LengthOfSecondZone" defaultValue={this.props.data.Length === undefined ? "3" : this.props.data.Length[1]} placeholder="3"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "LengthOfThirdZone" defaultValue={this.props.data.Length === undefined ? "3" : this.props.data.Length[2]} placeholder="3"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "LengthOfFourthZone" defaultValue={this.props.data.Length === undefined ? "3" : this.props.data.Length[3]} placeholder="3"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "LengthOfFifthZone" defaultValue={this.props.data.Length === undefined ? "3" : this.props.data.Length[4]} placeholder="3"></input>
                    </div>
                    <div className="zones">
                        <label className="name_param">Температура греющих газов, °С</label>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "TempGazOfFirstZone" defaultValue={this.props.data.TempGaz === undefined ? "1427" : this.props.data.TempGaz[0]} placeholder="1427"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "TempGazOfSecondZone" defaultValue={this.props.data.TempGaz === undefined ? "1427" : this.props.data.TempGaz[1]} placeholder="1427"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "TempGazOfThirdZone" defaultValue={this.props.data.TempGaz === undefined ? "1427" : this.props.data.TempGaz[2]} placeholder="1427"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "TempGazOfFourthZone" defaultValue={this.props.data.TempGaz === undefined ? "1427" : this.props.data.TempGaz[3]} placeholder="1427"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "TempGazOfFifthZone" defaultValue={this.props.data.TempGaz === undefined ? "1427" : this.props.data.TempGaz[4]} placeholder="1427"></input>
                    </div>
                    <div className="zones">
                        <label className="name_param">Действительное значение &#945;</label>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "AlfaRealOfFirstZone" defaultValue={this.props.data.AlfaReal === undefined ? "346" : this.props.data.AlfaReal[0]} placeholder="346"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "AlfaRealOfSecondZone" defaultValue={this.props.data.AlfaReal === undefined ? "352.5" : this.props.data.AlfaReal[1]} placeholder="352.5"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "AlfaRealOfThirdZone" defaultValue={this.props.data.AlfaReal === undefined ? "368.4" : this.props.data.AlfaReal[2]} placeholder="368.4"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "AlfaRealOfFourthZone" defaultValue={this.props.data.AlfaReal === undefined ? "435.6" : this.props.data.AlfaReal[3]} placeholder="435.6"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "AlfaRealOfFifthZone" defaultValue={this.props.data.AlfaReal === undefined ? "400" : this.props.data.AlfaReal[4]} placeholder="400"></input>
                    </div>
                    <div className="zones">
                        <label className="name_param">{/* Действительное значение */} Степень черноты &#949;</label>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "EPSOfFirstZone" defaultValue={this.props.data.EPS === undefined ? "0.488" : this.props.data.EPS[0]} placeholder="0.488"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "EPSOfSecondZone" defaultValue={this.props.data.EPS === undefined ? "0.5039" : this.props.data.EPS[1]} placeholder="0.5039"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "EPSOfThirdZone" defaultValue={this.props.data.EPS === undefined ? "0.5022" : this.props.data.EPS[2]} placeholder="0.5022"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "EPSOfFourthZone" defaultValue={this.props.data.EPS === undefined ? "0.557" : this.props.data.EPS[3]} placeholder="0.557"></input>
                        <input className="form-control" onKeyDown={this.handleKeypress.bind(this)} ref = "EPSOfFifthZone" defaultValue={this.props.data.EPS === undefined ? "0.6" : this.props.data.EPS[4]} placeholder="0.6"></input>
                    </div>
                </div>
                                   
                    <div className="culc">
                        <button type="button" className="btn btn-success" onClick={this.setModel}>Моделирование</button>
                        <button type="button" className="btn btn-success" onClick={this.setIdent}>Идентификация</button>
                    </div>
                </div>
           )
        }
}

export default Main;