import React, { Component } from 'react';
import '../styles/About.css';

class About extends Component {

    render() {
        return (
            <div className="container">
                <h4 className="about_header">Идентификация</h4>
                <p>Программа предназначена для выполненния следующих задач:</p>
                    <ul>
                        <li>расчет распределения температур по сечению металлической 
                            заготовки при известных параметрах внешнего теплообмена;
                        </li>
                        <li>идентификация параметров внешнего теплообмена по результатам 
                            измерений температуры поверхности металлической заготовки в отдельных точках при различной 
                            структуре граничных условий теплообмена в печи – конвекции и излучении.
                        </li>
                    </ul>
                <p><b>Разработчик:</b> Болгов А.Е.</p>
                <p>Версия 1.0</p>
                <address>
                    <strong>Поддержка:</strong><a href="mailto:bolgov1997@mail.ru"> bolgov1997@mail.ru</a><br/>
                </address>
            </div>
        )
    }
}

export default About;