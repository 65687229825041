import React, {Component} from 'react';
import '../styles/Menu.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


class Menu extends Component{
    
    setAbout = this.setAbout.bind(this);
    setMain = this.setMain.bind(this);
    setMaterial = this.setMaterial.bind(this);
    
    setAbout(){
        this.props.ShowComponent('About');
    }
    setMain(){
        this.props.ShowComponent('Main');
    }
    setMaterial(){
        this.props.ShowComponent('Material');
    }

    render()
        {
            return(
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Navbar.Brand href="" onClick={this.setMain.bind(this)}>
                        <img
                            alt="logo"
                            src="./logo.png"
                            className="d-inline-block"
                        ></img>
                        Идентификация
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        {/* <Nav.Link href="" onClick={this.setMaterial}>Справочник веществ</Nav.Link> */}
                        <Nav.Link href="" onClick={this.setAbout}>О программе</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
           )
        }
}

export default Menu;